import React from 'react';
import moment from 'moment-es6';
import { deviceType, browserName, osName } from 'react-device-detect';
// @ts-ignore
import FooterThumbIcon from '../../svgs/footer-thumb.svg';
// @ts-ignore
import ArrowRightRed from '../../svgs/arrow-right-dark-red.svg';
// @ts-ignore
import BrYellow from '../../svgs/br-yellow.svg';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';

interface props {
  data: any;
}

interface state {
  active: boolean;
  rating: number;
  feedbackType: string;
  numberValidation: boolean;
  emailValidation: boolean;
  textTopValidation: boolean;
  textBottomValidation: boolean;
  feedbackMaxLengthValidation: boolean;
  textTopValidationMsg: string;
  textBottomValidationMsg: string;
  submitting: boolean;
  submitted: boolean;
  hideLeft: boolean;
  leftText: string;
  email: string;
  yesNo: string;
  recaptchaValidationMsg: string;
  recaptchaValidation: boolean;
}

interface feedbackObj {
  [x: string]: any;
}

export default class FooterFeedback extends React.Component<props, state> {
  textTop: HTMLTextAreaElement;
  textBottom: HTMLTextAreaElement;
  feedbackText: HTMLTextAreaElement;
  emailInput: HTMLInputElement;
  recaptchaRef: React.RefObject<ReCAPTCHA>;

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      rating: 0,
      feedbackType: '',
      numberValidation: false,
      emailValidation: false,
      textTopValidation: false,
      textTopValidationMsg: 'Please fill section out',
      feedbackMaxLengthValidation: false,
      textBottomValidation: false,
      textBottomValidationMsg: 'Please fill section out',
      submitting: false,
      submitted: false,
      hideLeft: false,
      email: '',
      yesNo: '',
      leftText: 'Help us improve your experience',
      recaptchaValidationMsg: 'Please complete recpatcha',
      recaptchaValidation: false,
    };
    this.recaptchaRef = React.createRef();
  }

  componentDidMount = () => {};

  handleButtons = (feedbackType: string, yesNo?: string): void => {
    switch (feedbackType) {
      case 'feedback':
        this.setState(
          {
            active: true,
            feedbackType: feedbackType,
            submitting: false,
            submitted: false,
            yesNo: yesNo,
            hideLeft: false,
            leftText: 'Help us improve your experience',
          },
          () => {
            this.expandContent();
          }
        );
        break;
      case 'bug-report':
        this.setState(
          {
            active: true,
            feedbackType: feedbackType,
            submitting: false,
            submitted: false,
            hideLeft: false,
            leftText: 'Help us improve your experience',
          },
          () => {
            this.expandContent();
          }
        );
        break;
      case 'close':
        this.expandContent();
        setTimeout(() => {
          this.setState({
            active: false,
            rating: 0,
            feedbackType: '',
          });
        }, 400);
        break;
      default:
        break;
    }
  };

  capitalizeFirstLetter = (string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const token = await this.recaptchaRef.current.getValue();
    const dateTime = moment().format('MMMM Do YYYY, h:mm:ss a');
    const URL = window.location.href;
    const path = window.location.pathname;
    const feedbackAPI =
      "https://ivvoh0ors6.execute-api.ap-southeast-2.amazonaws.com/default/det-learning-sequences-pdf-upload?feedback='true'";

    let feedbackObj: feedbackObj = {
      device: this.capitalizeFirstLetter(
        deviceType === 'browser' ? 'desktop' : deviceType
      ),
      browser: browserName,
      os: osName,
      URL: URL,
      path: path,
      dateTime: dateTime,
      type: this.state.feedbackType,
      recaptchaToken: token,
    };

    if (
      this.state.feedbackType == 'feedback' &&
      this.allFeedbackValidationCheck() &&
      this.feedbackLengthValidation(this.feedbackText.value)
    ) {
      this.setState({ submitting: true });
      feedbackObj.yesNo = this.state.yesNo;
      feedbackObj.rating = this.state.rating + ' out of 10';
      feedbackObj.text = (document.getElementById(
        'feedback-form__text'
      ) as HTMLTextAreaElement).value;

      try {
        await fetch(feedbackAPI, {
          method: 'POST',
          body: JSON.stringify(feedbackObj),
        });
      } catch (error) {
        console.log(error);
      }
      this.formSubmitted();
    } else if (
      this.state.feedbackType == 'bug-report' &&
      this.allBugValidationCheck()
    ) {
      this.setState({ submitting: true });
      feedbackObj.top = (document.getElementById(
        'feedback-form__text-top'
      ) as HTMLTextAreaElement).value;
      feedbackObj.bottom = (document.getElementById(
        'feedback-form__text-bottom'
      ) as HTMLTextAreaElement).value;

      try {
        await fetch(feedbackAPI, {
          method: 'POST',
          body: JSON.stringify(feedbackObj),
        });
      } catch (error) {
        console.log(error);
      }

      this.formSubmitted();
    }
  };

  numberButton = (e) => {
    e.preventDefault();
    // Set value of number
    let value = e.target.value;

    this.setState({
      rating: value,
      numberValidation: false,
    });

    // Remove any active classes on buttons
    let numberButtons = document.querySelectorAll('.feedback-form__number');

    for (let button = 0; button < numberButtons.length; button++) {
      numberButtons[button].classList.remove('active');
    }

    // Add active class on button
    e.target.classList.add('active');
  };

  numberValidation = (): boolean => {
    if (this.state.rating == 0) {
      this.setState({ numberValidation: true });
      return false;
    } else {
      this.setState({ numberValidation: false });
      return true;
    }
  };

  checkEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  emailValidation = (): boolean => {
    if (this.emailInput.value !== '') {
      if (this.checkEmail(this.emailInput.value)) {
        this.setState({ emailValidation: false });
        return true;
      } else {
        this.setState({ emailValidation: true });
        return false;
      }
    } else {
      this.setState({ emailValidation: false });
      return true;
    }
  };

  feedbackLengthValidation = (value): boolean => {
    if (value.length > 200) {
      this.setState({ feedbackMaxLengthValidation: true });
      return false;
    } else {
      this.setState({ feedbackMaxLengthValidation: false });
      return true;
    }
  };

  feedBackValidation = (el: string): void => {
    let recaptchaToken = this.recaptchaRef.current.getValue();
    if (!recaptchaToken.length) {
      this.setState({
        recaptchaValidation: true,
        recaptchaValidationMsg: 'Please complete recaptcha section out',
      });
    }
  };

  bugValidation = (el: string): void => {
    let textTopVal = this.textTop.value;
    let textBottomVal = this.textBottom.value;
    let recaptchaToken = this.recaptchaRef.current.getValue();

    switch (el) {
      case 'textTop':
        if (textTopVal == '') {
          this.setState({
            textTopValidation: true,
            textTopValidationMsg: 'Please fill section out',
          });
        } else if (textTopVal.length > 200) {
          this.setState({
            textTopValidation: true,
            textTopValidationMsg:
              'This field has a max length of 200 characters',
          });
        }
        break;
      case 'textBottom':
        if (textBottomVal == '') {
          this.setState({
            textBottomValidation: true,
            textBottomValidationMsg: 'Please fill section out',
          });
        } else if (textBottomVal.length > 200) {
          this.setState({
            textBottomValidation: true,
            textBottomValidationMsg:
              'This field has a max length of 200 characters',
          });
        }
        break;
      case 'recaptcha':
        if (!recaptchaToken.length) {
          this.setState({
            recaptchaValidation: true,
            recaptchaValidationMsg: 'Please complete recaptcha section out',
          });
        }
    }
  };

  allFeedbackValidationCheck = (): boolean => {
    let numberValidation = this.numberValidation();
    let recaptchaToken = this.recaptchaRef.current.getValue();
    this.feedBackValidation('recaptcha');
    if (numberValidation || recaptchaToken.length) {
      return true;
    } else {
      return false;
    }
  };

  allBugValidationCheck = (): boolean => {
    let textTopVal = this.textTop.value;
    let textBottomVal = this.textBottom.value;
    let recaptchaToken = this.recaptchaRef.current.getValue();

    this.bugValidation('textTop');
    this.bugValidation('textBottom');
    this.bugValidation('recaptcha');

    if (
      textTopVal == '' ||
      textBottomVal == '' ||
      textTopVal.length > 200 ||
      textBottomVal.length > 200 ||
      !recaptchaToken.length
    ) {
      return false;
    } else {
      return true;
    }
  };

  removeValidation = (el) => {
    switch (el) {
      case 'textTop':
        this.setState({ textTopValidation: false });
        break;
      case 'textBottom':
        this.setState({ textBottomValidation: false });
        break;
      case 'feedbackText':
        this.setState({ feedbackMaxLengthValidation: false });
        break;
      case 'recaptcha':
        this.setState({ recaptchaValidation: false });
        break;
    }
  };

  expandContent = () => {
    let feedbackContent: HTMLElement = document.querySelector(`.fbcontent`);
    let elHeight = feedbackContent.scrollHeight + 80;

    if (feedbackContent.classList.contains('active')) {
      feedbackContent.style.maxHeight = 0 + 'px';
      feedbackContent.classList.remove('active');
    } else {
      feedbackContent.style.maxHeight = elHeight + 'px';
      feedbackContent.classList.add('active');
    }
  };

  formSubmitted = () => {
    this.setState(
      {
        submitted: true,
        hideLeft: true,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              hideLeft: false,
              leftText: 'Thank you for your feedback',
            },
            () => {
              setTimeout(() => {
                this.handleButtons('close');
              }, 2000);
            }
          );
        }, 400);
      }
    );
  };

  render() {
    let data = this.props.data;

    return (
      <>
        <div className='footer__feedback'>
          <div className='static-container'>
            <div
              className={`footer__feedback-left ${this.state.active &&
                'active'}`}
            >
              <FooterThumbIcon />
              <span className='footer__feedback-useful'>
                Is this page useful?
              </span>
              <button
                className='footer__feedback-useful footer__feedback-button'
                onClick={() => this.handleButtons('feedback', 'Yes')}
                aria-controls='feedback-content'
                aria-expanded={this.state.active}
                id='yes-button'
              >
                Yes
              </button>
              <button
                className='footer__feedback-useful footer__feedback-button'
                onClick={() => this.handleButtons('feedback', 'No')}
                aria-controls='feedback-content'
                aria-expanded={this.state.active}
                id='no-button'
              >
                No
              </button>
            </div>
            <div className={`footer__feedback-right ${this.state.active && 'active'}`}>
                <button 
                  className="footer__bug-report footer__feedback-button right"
                  onClick={() => this.handleButtons('bug-report')}
                  aria-controls="feedback-content"
                  aria-expanded={this.state.active}
                  id="bug-report-button"
                >
                  Is there anything wrong with this page?
                </button>
            </div>
            <div className={`footer__feedback-close ${this.state.active && 'active'}`}>
                <button 
                  className="footer__bug-report footer__feedback-button"
                  onClick={() => this.handleButtons('close')}
                  aria-controls="feedback-content"
                  aria-expanded={this.state.active}
                  id="close-button"
           
                >
                  Close X
                </button> 
            </div>
          </div>
          <div className={`footer__feedback-content fbcontent`}
            id="feedback-content"
            role="region"
            aria-labelledby='yes-button no-button bug-report-button close-button' 
            >
            <div className="static-container">
              <div className={`fbcontent-left ${this.state.hideLeft ? 'hide' : ''}`}>
                <h2 className={`fbcontent-title`}>{this.state.leftText}</h2>
                <BrYellow />
              </div>
              <div
                className={`fbcontent-right ${this.state.submitting &&
                  'submitting'} ${this.state.submitted && 'submitted'}`}
              >
                {this.state.feedbackType == 'feedback' ? (
                  <form
                    className='feedback-form'
                    onSubmit={(e) => this.handleSubmit(e)}
                  >
                    <label htmlFor='numbers'>
                      How would you rate your experience? *
                    </label>
                    <div className='feedback-form__numbers' id='numbers'>
                      <button
                        className='feedback-form__number'
                        value='1'
                        onClick={(e) => this.numberButton(e)}
                      >
                        1
                      </button>
                      <button
                        className='feedback-form__number'
                        value='2'
                        onClick={(e) => this.numberButton(e)}
                      >
                        2
                      </button>
                      <button
                        className='feedback-form__number'
                        value='3'
                        onClick={(e) => this.numberButton(e)}
                      >
                        3
                      </button>
                      <button
                        className='feedback-form__number'
                        value='4'
                        onClick={(e) => this.numberButton(e)}
                      >
                        4
                      </button>
                      <button
                        className='feedback-form__number'
                        value='5'
                        onClick={(e) => this.numberButton(e)}
                      >
                        5
                      </button>
                      <button
                        className='feedback-form__number'
                        value='6'
                        onClick={(e) => this.numberButton(e)}
                      >
                        6
                      </button>
                      <button
                        className='feedback-form__number'
                        value='7'
                        onClick={(e) => this.numberButton(e)}
                      >
                        7
                      </button>
                      <button
                        className='feedback-form__number'
                        value='8'
                        onClick={(e) => this.numberButton(e)}
                      >
                        8
                      </button>
                      <button
                        className='feedback-form__number'
                        value='9'
                        onClick={(e) => this.numberButton(e)}
                      >
                        9
                      </button>
                      <button
                        className='feedback-form__number'
                        value='10'
                        onClick={(e) => this.numberButton(e)}
                      >
                        10
                      </button>
                      <span
                        className={`feedback-form__numbers-validation ${
                          this.state.numberValidation ? 'active' : ''
                        }`}
                      >
                        Please choose a number above
                      </span>
                    </div>
                    <div className='feedback-form__text'>
                      <label
                        htmlFor='feedback-form__text'
                        className='feedback-form__label'
                      >
                        Please write a few words (optional)
                      </label>
                      <textarea
                        name=''
                        id='feedback-form__text'
                        cols={30}
                        rows={5}
                        ref={(textarea) => (this.feedbackText = textarea)}
                        className={`${
                          this.state.feedbackMaxLengthValidation ? 'error' : ''
                        }`}
                        onBlur={() =>
                          this.feedbackLengthValidation(this.feedbackText.value)
                        }
                        onFocus={() => this.removeValidation('feedbackText')}
                      />
                      <span
                        className={`feedback-form__length-validation ${
                          this.state.feedbackMaxLengthValidation ? 'active' : ''
                        }`}
                      >
                        This feedback has a maximum length of 200 characters
                      </span>
                      <ReCAPTCHA
                        ref={this.recaptchaRef}
                        sitekey='6LetSPEfAAAAAE3XyCGwbPthr9YyfcxvFcFMV_G0'
                        onFocus={() => this.removeValidation('recaptcha')}
                      />
                      <div
                        className={`feedback-form__recaptcha-validation ${
                          this.state.recaptchaValidation ? 'active' : ''
                        }`}
                      >
                        {this.state.recaptchaValidationMsg}
                      </div>
                    </div>

                    <button className='feedback-form__submit'>
                      Send <ArrowRightRed />
                    </button>
                  </form>
                ) : (
                  <form
                    className='feedback-form'
                    onSubmit={(e) => this.handleSubmit(e)}
                  >
                    <label
                      htmlFor='feedback-form__text-top'
                      className='feedback-form__label'
                    >
                      What were you doing?
                    </label>
                    <textarea
                      name=''
                      id='feedback-form__text-top'
                      className={`${
                        this.state.textTopValidation ? 'error' : ''
                      }`}
                      cols={30}
                      rows={5}
                      ref={(textarea) => (this.textTop = textarea)}
                      onBlur={() => this.bugValidation('textTop')}
                      onFocus={() => this.removeValidation('textTop')}
                    />
                    <span
                      className={`feedback-form__text-top-validation ${
                        this.state.textTopValidation ? 'active' : ''
                      }`}
                    >
                      {this.state.textTopValidationMsg}
                    </span>
                    <label
                      htmlFor='feedback-form__text-bottom'
                      className='feedback-form__label'
                    >
                      What went wrong?
                    </label>
                    <textarea
                      name=''
                      id='feedback-form__text-bottom'
                      className={`${
                        this.state.textBottomValidation ? 'error' : ''
                      }`}
                      cols={30}
                      rows={5}
                      ref={(textarea) => (this.textBottom = textarea)}
                      onBlur={() => this.bugValidation('textBottom')}
                      onFocus={() => this.removeValidation('textBottom')}
                    />
                    <span
                      className={`feedback-form__text-bottom-validation ${
                        this.state.textBottomValidation ? 'active' : ''
                      }`}
                    >
                      {this.state.textBottomValidationMsg}
                    </span>
                    <ReCAPTCHA
                      ref={this.recaptchaRef}
                      sitekey='6LetSPEfAAAAAE3XyCGwbPthr9YyfcxvFcFMV_G0'
                      onFocus={() => this.removeValidation('recaptcha')}
                    />
                    <div
                      className={`feedback-form__recaptcha-validation ${
                        this.state.recaptchaValidation ? 'active' : ''
                      }`}
                    >
                      {this.state.recaptchaValidationMsg}
                    </div>
                    <button className={`feedback-form__submit`}>
                      Send <ArrowRightRed />
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
